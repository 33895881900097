import { createRouter, createWebHistory } from 'vue-router'
import store from '../store';
import TokenService from '@/services/token.service';
import api from "@/services/api";

import Login from '../views/Login.vue'

/*
function dynamicPropsFn(route) {
	const now = new Date()
	return {
		name: (now.getFullYear() + parseInt(route.params.id)) + '!',
		piid: 666
	}
}

 meta: { requiresAuth: false }

*/

const routes = [
	{
		path: '/',
		name: 'home',
		component: () => import('../views/Home.vue'),
		meta: { sideBar: 'Home' }
	},
	{
		path: '/companies',
		name: 'companies',
		component: () => import('../views/Company/Companies.vue'),
		meta: { sideBar: 'Companies' }
	},
	{
		path: '/company/:id',
		name: 'company',
		component: () => import('../views/Company/Company.vue'),
		props: true,
		meta: { sideBar: 'Companies' }
	},
	{
		path: '/customers',
		name: 'customers',
		component: () => import('../views/Company/Customers.vue'),
		meta: { sideBar: 'Customers' }
	},
	{
		path: '/customer/:id',
		name: 'customer',
		component: () => import('../views/Company/Customer.vue'),
		props: true,
		meta: { sideBar: 'Customers' }
	},
	{
		path: '/test-drives',
		name: 'test-drives',
		component: () => import('../views/Vehicles/TestDrives.vue'),
		meta: { sideBar: 'Test Drives' }
	},
	{
		path: '/test-drive/:id',
		name: 'test-drive',
		component: () => import('../views/Vehicles//TestDrive.vue'),
		props: true,
		meta: { sideBar: 'Test Drives' }
	},
	{
		path: '/vehicles',
		name: 'vehicles',
		component: () => import('../views/Vehicles//Vehicles.vue'),
		meta: { sideBar: 'Vehicles' }
	},
	{
		path: '/vehicle-setup',
		name: 'vehicle-setup',
		component: () => import('../views/Vehicles//VehicleSetup.vue'),
		meta: { sideBar: 'Vehicles' }
	},
	{
		path: '/vehicle-brand/:id',
		name: 'vehicle-brand',
		component: () => import('../views/Vehicles//VehicleSetupBrand.vue'),
		props: true,
		meta: { sideBar: 'Vehicles' }
	},
	{
		path: '/vehicle-model/:id',
		name: 'vehicle-model',
		component: () => import('../views/Vehicles//VehicleSetupModel.vue'),
		props: true,
		meta: { sideBar: 'Vehicles' }
	},
	{
		path: '/vehicle-orders',
		name: 'vehicle-orders',
		component: () => import('../views/Vehicles//VehicleOrders.vue'),
		meta: { sideBar: 'Vehicles' }
	},
	{
		path: '/vehicle-order-new',
		name: 'vehicle-order-new',
		component: () => import('../views/Vehicles//VehicleOrderNew.vue'),
		meta: { sideBar: 'Vehicles' }
	},
	{
		path: '/vehicle-order/:id',
		name: 'vehicle-order',
		component: () => import('../views/Vehicles//VehicleOrder.vue'),
		props: true,
		meta: { sideBar: 'Vehicles' }
	},
	{
		path: '/vehicle-stock',
		name: 'vehicle-stock',
		component: () => import('../views/Vehicles//VehicleStock.vue'),
		meta: { sideBar: 'Vehicles' }
	},
	{
		path: '/vehicle/:vin',
		name: 'vehicle',
		component: () => import('../views/Vehicles//Vehicle.vue'),
		props: true,
		meta: { sideBar: 'Vehicles' }
	},
	{
		path: '/vehicle-trade',
		name: 'vehicle-trade',
		component: () => import('../views/Vehicles//VehicleTrade.vue'),
		meta: { sideBar: 'Vehicles' }
	},
	{
		path: '/service',
		name: 'service',
		component: () => import('../views/Service/Service.vue'),
		meta: { sideBar: 'Service' }
	},
	{
		path: '/warranty-claims',
		name: 'warranty-claims',
		component: () => import('../views/Service/WarrantyClaims.vue'),
		meta: { sideBar: 'Service' }
	},
	{
		path: '/warranty-claim/:id',
		name: 'warranty-claim',
		component: () => import('../views/Service/WarrantyClaim.vue'),
		props: true,
		meta: { sideBar: 'Service' }
	},
	{
		path: '/maintenances',
		name: 'maintenances',
		component: () => import('../views/Service/Maintenances.vue'),
		meta: { sideBar: 'Service' }
	},
	{
		path: '/maintenance/:id',
		name: 'maintenance',
		component: () => import('../views/Service/Maintenance.vue'),
		props: true,
		meta: { sideBar: 'Service' }
	},
	{
		path: '/service-reports',
		name: 'service-reports',
		component: () => import('../views/Service/ServiceReports.vue'),
		meta: { sideBar: 'Service' }
	},
	{
		path: '/service-report/:id',
		name: 'service-report',
		component: () => import('../views/Service/ServiceReport.vue'),
		props: true,
		meta: { sideBar: 'Service' }
	},
	/*


	{
		path: '/history',
		name: 'history',
		component: () => import('../views/History.vue'),
		meta: { sideBar: 'Service' }
	},
*/
	{
		path: '/products',
		name: 'products',
		component: () => import('../views/Products/Products.vue'),
		meta: { sideBar: 'Products' }
	},
	{
		path: '/product-list',
		name: 'product-list',
		component: () => import('../views/Products/ProductList.vue'),
		meta: { sideBar: 'Products' }
	},
	{
		path: '/product/:id',
		name: 'product',
		component: () => import('../views/Products/Product.vue'),
		props: true,
		meta: { sideBar: 'Products' }
	},
	{
		path: '/product-diagrams',
		name: 'product-diagrams',
		component: () => import('../views/Products/ProductDiagrams.vue'),
		meta: { sideBar: 'Products' }
	},
	{
		path: '/product-categories',
		name: 'product-categories',
		component: () => import('../views/Products/ProductCategories.vue'),
		meta: { sideBar: 'Products' }
	},
	{
		path: '/stock-orders',
		name: 'stock-orders',
		component: () => import('../views/Products/StockOrders.vue'),
		meta: { sideBar: 'Products' }
	},
	{
		path: '/stock-order/:id',
		name: 'stock-order',
		component: () => import('../views/Products/StockOrder.vue'),
		props: true,
		meta: { sideBar: 'Products' }
	},
	{
		path: '/stock-invoices',
		name: 'stock-invoices',
		component: () => import('../views/Products/StockInvoices.vue'),
		meta: { sideBar: 'Products' }
	},
	{
		path: '/stock-invoice/:id',
		name: 'stock-invoice',
		component: () => import('../views/Products/StockInvoice.vue'),
		props: true,
		meta: { sideBar: 'Products' }
	},
	{
		path: '/stock-takings',
		name: 'stock-takings',
		component: () => import('../views/Products/StockTakings.vue'),
		meta: { sideBar: 'Products' }
	},
	{
		path: '/stock-taking/:id',
		name: 'stock-taking',
		component: () => import('../views/Products/StockTaking.vue'),
		props: true,
		meta: { sideBar: 'Products' }
	},
	{
		path: '/stock-products',
		name: 'stock-products',
		component: () => import('../views/Products/StockProducts.vue'),
		meta: { sideBar: 'Products' }
	},
	{
		path: '/stock-product/:id',
		name: 'stock-product',
		component: () => import('../views/Products/StockProduct.vue'),
		props: true,
		meta: { sideBar: 'Products' }
	},
	{
		path: '/product-orders',
		name: 'product-orders',
		component: () => import('../views/Products/ProductOrders.vue'),
		meta: { sideBar: 'Products' }
	},
	{
		path: '/product-order/:id',
		name: 'product-order',
		component: () => import('../views/Products/ProductOrder.vue'),
		props: true,
		meta: { sideBar: 'Products' }
	},
	{
		path: '/product-invoices',
		name: 'product-invoices',
		component: () => import('../views/Products/ProductInvoices.vue'),
		meta: { sideBar: 'Products' }
	},
	{
		path: '/product-invoice/:id',
		name: 'product-invoice',
		component: () => import('../views/Products/ProductInvoice.vue'),
		props: true,
		meta: { sideBar: 'Products' }
	},
	{
		path: '/website', /// TODO unused for now
		name: 'website',
		component: () => import('../views/Website.vue'),
	},

	{
		path: '/config',
		name: 'config',
		component: () => import('../views/SystemConfig.vue'),
		meta: { sideBar: 'System Config' }
	},
	{
		path: '/login',
		name: 'login',
		component: Login,
	},
	{ path: '/:pathMatch(.*)*', redirect: '/' }
];

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes,
	scrollBehavior() {
		document.getElementById('app').scrollIntoView();
	}
})

function check(to) {
	let input = {
		pathTo: to,
		companyID: store.getters.userCompany ? store.getters.userCompany.companyID : -1,
	}
	return api
		.post("/users/check", input)
		.then((response) => {
			return response;
		})
		.catch((error) => {
			store.dispatch('error', error);
			return null;
		});
}

router.beforeEach((to, from, next) => {
	const preLoading = document.getElementById('loading_wrap');
	if (preLoading) {
		preLoading.style.display = 'none';
	}
	if (to.path === '/login') {
		TokenService.removeUser();
	}
	const publicPages = ['/login'];
	const authRequired = !publicPages.includes(to.path);
	const loggedIn = TokenService.getUser();
	if (authRequired && !loggedIn) {
		sessionStorage.setItem('redirectPath', to.path);
		next('/login');
	} else {
		if (to.path === '/login') {
			next();
		}
		else {
			check(to.path).then((response) => {
				if (response ? response.data.result === 'OK' : false) {
					store.dispatch('auth/setPermissions', response.data.permissions);
					next();
				}
				else {
					if (response) {
						store.dispatch('error', response.data);
						store.dispatch('reset');
						if (response.data.url === '/login') {
							sessionStorage.removeItem('redirectPath');
							store.dispatch('auth/logout');
						}
						else {
							if (response.data.url) next(response.data.url);
						}
					}
				}
			});
		}
	}
});

export default router;
