import api from './api';
import store from '../store';

class TestDriveService {

	search(input) {
		return api
			.post("/testdrive/search", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return [];
			});
	}

	getTestDrive(input) {
		return api
			.post("/testdrive/get-test-drive", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}

	update(model) {
		return api
			.post("/testdrive/update", model)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}

	sign(model) {
		return api
			.post("/testdrive/sign", model)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}

	removeSign(model) {
		return api
			.post("/testdrive/remove-sign", model)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}

	create(input) {
		return api
			.post("/testdrive/create", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				//store.dispatch('error', error);
				return { error: error.message };
			});
	}

	getLookups(input) {
		return api
			.post("/testdrive/get-lookups", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}

}

export default new TestDriveService();
