import api from './api';
import store from '../store';

class CustomerService {

  search(input) {
    return api
      .post("/customer/search", input)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        store.dispatch('error', error);
       return {};
      });
  }

  getProfile(input) {
    return api
      .post("/customer/get-customer", input)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        store.dispatch('error', error);
       return {};
      });
  }

  checkEmail(input) {
    return api
      .post("/customer/check-email", input)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        store.dispatch('error', error);
       return {};
      });
  }

  saveCustomer(input) {
    return api
      .post("/customer/save-customer", input)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        store.dispatch('error', error);
       return {};
      });
  }

}

export default new CustomerService();
