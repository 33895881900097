import api from './api';
import store from '../store';

class ProductService {

	getProductCategories(input) {
		return api
			.post("/product/get-categories", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return [];
			});
	}

	saveProductCategory(input) {
		return api
			.post("/product/save-category", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}

	saveProductCategoriesSortOrder(input) {
		return api
			.post("/product/save-category-sort", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}

	deleteProductCategory(input) {
		return api
			.post("/product/delete-category", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}

	search(input) {
		return api
			.post("/product/search", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return [];
			});
	}

	get(input) {
		return api
			.post("/product/get", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}

	getProductManufacturers(input) {
		return api
			.post("/product/get-manufacturers", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return [];
			});
	}

	saveProduct(input) {
		return api
			.post("/product/save-product", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				return { error: error.message };
			});
	}

	saveProductReplacement(input) {
		return api
			.post("/product/save-product-replacement", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				return { error: error.message };
			});
	}

	checkProductCode(input) {
		return api
			.post("/product/check-product", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}

	getProductSuppliers(input) {
		return api
			.post("/product/get-suppliers", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return [];
			});
	}

	getWarehouseLocations(input) {
		return api
			.post("/product/get-warehouse-locations", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return [];
			});
	}

	getCostTypes() {
		return api
			.get("/product/get-cost-types")
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return [];
			});
	}

	// stock orders //

	searchStockOrders(input) {
		return api
			.post("/product/search-stock-orders", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return [];
			});
	}

	saveStockOrder(input) {
		return api
			.post("/product/save-stock-order", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				return { error: error.message };
			});
	}

	deleteStockOrder(input) {
		return api
			.post("/product/delete-stock-order", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				return { error: error.message };
			});
	}

	getStockOrder(input) {
		return api
			.post("/product/get-stock-order", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}

	saveStockOrderProducts(input) {
		return api
			.post("/product/save-stock-order-products", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				return { error: error.message };
			});
	}

	// stock invoices //

	searchStockInvoices(input) {
		return api
			.post("/product/search-stock-invoices", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return [];
			});
	}

	saveStockInvoice(input) {
		return api
			.post("/product/save-stock-invoice", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				return { error: error.message };
			});
	}

	deleteStockInvoice(input) {
		return api
			.post("/product/delete-stock-invoice", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				//store.dispatch('error', error);
				return { error: error.message };
			});
	}

	getStockInvoice(input) {
		return api
			.post("/product/get-stock-invoice", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}

	saveStockInvoiceCost(input) {
		return api
			.post("/product/save-stock-invoice-cost", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				return { error: error.message };
			});
	}

	deleteStockInvoiceCost(input) {
		return api
			.post("/product/delete-stock-invoice-cost", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				return { error: error.message };
			});
	}

	getStockOrdersForInvoice(input) {
		return api
			.post("/product/get-stock-orders-for-invoice", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}

	saveStockInvoiceProducts(input) {
		return api
			.post("/product/save-stock-invoice-products", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				return { error: error.message };
			});
	}

	saveStockInvoiceRow(input) {
		return api
			.post("/product/save-stock-invoice-row", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				return { error: error.message };
			});
	}

	/// stock takings ///

	searchStockTakings(input) {
		return api
			.post("/product/search-stock-takings", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return [];
			});
	}

	saveStockTaking(input) {
		return api
			.post("/product/save-stock-taking", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				return { error: error.message };
			});
	}

	getStockTaking(input) {
		return api
			.post("/product/get-stock-taking", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}

	deleteStockTaking(input) {
		return api
			.post("/product/delete-stock-taking", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				//store.dispatch('error', error);
				return { error: error.message };
			});
	}

	saveStockTakingAllocation(input) {
		return api
			.post("/product/save-stock-taking-allocation", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				return { error: error.message };
			});
	}

	deleteStockTakingAllocation(input) {
		return api
			.post("/product/delete-stock-taking-allocation", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				return { error: error.message };
			});
	}

	saveStockTakingProducts(input) {
		return api
			.post("/product/save-stock-taking-products", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				return { error: error.message };
			});
	}

	deleteStockTakingProduct(input) {
		return api
			.post("/product/delete-stock-taking-product", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				return { error: error.message };
			});
	}

	saveStockTakingProductRow(input) {
		return api
			.post("/product/save-stock-taking-product-row", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				return { error: error.message };
			});
	}




	/// stock products ///

	searchStock(input) {
		return api
			.post("/product/search-stock", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return [];
			});
	}

	getStockProduct(input) {
		return api
			.post("/product/get-stock-product", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}

	saveStockProductLocation(input) {
		return api
			.post("/product/save-stock-product-location", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				return { error: error.message };
			});
	}

	/// product orders ///

	searchProductOrders(input) {
		return api
			.post("/product/search-product-orders", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return [];
			});
	}

	getProductOrder(input) {
		return api
			.post("/product/get-product-order", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}

	saveProductOrder(input) {
		return api
			.post("/product/save-product-order", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				return { error: error.message };
			});
	}

	deleteProductOrder(input) {
		return api
			.post("/product/delete-product-order", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				return { error: error.message };
			});
	}

	saveProductOrderProducts(input) {
		return api
			.post("/product/save-product-order-products", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				return { error: error.message };
			});
	}

	/// product invoices ///

	getProductPurchasers(input) {
		return api
			.post("/product/get-product-purchasers", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return [];
			});
	}

	searchProductInvoices(input) {
		return api
			.post("/product/search-product-invoices", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return [];
			});
	}

	getProductInvoice(input) {
		return api
			.post("/product/get-product-invoice", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}

	saveProductInvoice(input) {
		return api
			.post("/product/save-product-invoice", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				return { error: error.message };
			});
	}

	deleteProductInvoice(input) {
		return api
			.post("/product/delete-product-invoice", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				return { error: error.message };
			});
	}

	saveProductInvoiceCost(input) {
		return api
			.post("/product/save-product-invoice-cost", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				return { error: error.message };
			});
	}

	deleteProductInvoiceCost(input) {
		return api
			.post("/product/delete-product-invoice-cost", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				return { error: error.message };
			});
	}

	getProductOrdersForInvoice(input) {
		return api
			.post("/product/get-product-orders-for-invoice", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return [];
			});
	}

	saveProductInvoiceProducts(input) {
		return api
			.post("/product/save-product-invoice-products", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				return { error: error.message };
			});
	}

	deleteProductInvoiceProducts(input) {
		return api
			.post("/product/delete-product-invoice-products", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				return { error: error.message };
			});
	}

	saveProductInvoiceNewProducts(input) {
		return api
			.post("/product/save-product-invoice-new-products", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				return { error: error.message };
			});
	}

	saveProductAllocation(input) {
		return api
			.post("/product/save-product-allocation", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				return { error: error.message };
			});
	}

	getPaymentOptions(input) {
		return api
			.post("/product/get-payment-options", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}

	saveProductInvoicePayment(input) {
		return api
			.post("/product/save-product-invoice-payment", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}

	deleteProductInvoicePayment(input) {
		return api
			.post("/product/delete-product-invoice-payment", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}

	getProductInvoicePrint(input) {
		return api
			.post("/product/get-product-invoice-print", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}

	creditProductInvoice(input) {
		return api
			.post("/product/credit-product-invoice", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				return { error: error.message };
			});
	}

	saveProductDiscount(input) {
		return api
			.post("/product/save-product-discount", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}

	deleteProductDiscount(input) {
		return api
			.post("/product/delete-product-discount", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}

	getProductCategoryDiscounts(input) {
		return api
			.post("/product/get-product-category-discounts", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}





	getPoductForVehicleBrands(input) {
		return api
			.post("/product/get-product-for-vehicle-brands", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}

	saveProductFor(input) {
		return api
			.post("/product/save-product-for", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}

	deleteProductFor(input) {
		return api
			.post("/product/delete-product-for", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}




}

export default new ProductService();
