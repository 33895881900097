import api from './api';
import store from '../store';

class CommonService {

	getPlatformLanguages() {
		return api
			.get("/common/get-platform-languages")
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}

	getCountries(input) {
		return api
			.post("/common/get-countries", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}

	getWebLanguages() {
		return api
			.get("/common/get-web-languages")
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}

	listLanguages(input) {
		return api
			.post("/common/list-languages", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}

	getLanguages() {
		return api
			.get("/common/get-languages")
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}

	saveLanguage(input) {
		return api
			.post("/common/save-language", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}

	deleteLanguage(input) {
		return api
			.post("/common/delete-language", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}

	saveLanguageSortOrder(input) {
		return api
			.post("/common/save-language-sort", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}

	getMarkets() {
		return api
			.get("/common/get-markets")
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}

	saveMarket(input) {
		return api
			.post("/common/save-market", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}

	restartMarketWebsite(input) {
		return api
			.post("/common/restart-market-website", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}

	deleteMarket(input) {
		return api
			.post("/common/delete-market", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}

	saveMarketSortOrder(input) {
		return api
			.post("/common/save-market-sort", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}

	getWebPages() {
		return api
			.get("/common/get-webpages")
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}

	getWebLogs() {
		return api
			.get("/common/get-weblogs")
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}
	
	getPaymentOptions() {
		return api
			.get("/common/get-payment-options")
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}

	savePaymentOptions(input) {
		return api
			.post("/common/save-payment-options", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}

	getAppSettings() {
		return api
			.get("/common/get-app-settings")
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}

	saveAppSettings(input) {
		return api
			.post("/common/save-app-settings", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}

	checkFile(input) {
		return api
			.post("/file/check", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}

	getFiles(input) {
		return api
			.post("/file/list", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}

	deleteFiles(input) {
		return api
			.post("/file/delete", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}

	saveFileSortOrder(input) {
		return api
			.post("/file/save-file-sort", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}

	pingServer() {
		return api.get('/users/ping');
	}

	getWebObjects() {
		return api
			.get("/common/get-webobjects")
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}

	saveWebObjectElement(input) {
		return api
			.post("/common/save-webobject-element", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}

	getWebObjectSources(input) {
		return api
			.post("/common/get-webobject-sources", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}

	getWebObjectTranslation(input) {
		return api
			.post("/common/get-webobject-translation", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}

	saveWebObjectTranslation(input) {
		return api
			.post("/common/save-webobject-translation", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}

	getDiscountProfiles(input) {
		return api
			.post("/common/get-discount-profiles", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}

	async getGeoData(search) {
		const action = search.action;
		const country = encodeURIComponent(search.countryCode);
		const region1 = encodeURIComponent(search.region1);
		const region2 = encodeURIComponent(search.region2);
		const region3 = encodeURIComponent(search.region3);
		const city = encodeURIComponent(search.city);
		const post = encodeURIComponent(search.post);
		const text = encodeURIComponent(search.text);

		var url = '';

		if (action === 'country') {
			url += '&rows=1&facet=admin_name1&refine.country_code=' + country;
		}

		if (action === 'region1') {
			url += '&rows=1&facet=admin_name2&facet=admin_name3&refine.country_code=' + country;
			url += '&refine.admin_name1=' + region1;
		}

		if (action === 'region2') {
			url += '&rows=1&facet=admin_name3&refine.country_code=' + country;
			url += '&refine.admin_name1=' + region1;
			url += '&refine.admin_name2=' + region2;
		}

		if (action === 'region3' || action === 'city' || action === 'postCode') {
			url += '&rows=10000&facet=admin_name3&refine.country_code=' + country;
			url += '&refine.admin_name1=' + region1;
			if (region2 != '') url += '&refine.admin_name2=' + region2;
			if (region3 != '') url += '&refine.admin_name3=' + region3;
			if (city != '') url += '&refine.place_name=' + city;
			if (post != '') url += '&refine.postal_code=' + post;
		}

		if (text !== undefined && text !== 'undefined' && text !== '') {
			url += '&q=' + text;
		}

		if (url == '') return;

		url = 'https://public.opendatasoft.com/api/records/1.0/search/?dataset=geonames-postal-code' + url;

		const response = await fetch(url);
		return await response.json();

	}

	async getWebCities(letter, country) {
		letter = encodeURIComponent(letter);
		country = encodeURIComponent(country);
		const response = await fetch(
			`https://public.opendatasoft.com/api/records/1.0/search/?dataset=geonames-postal-code&rows=1&facet=admin_name2&facet=admin_code2&refine.country_code=${country}`
		);
		return await response.json();
	}

	async getXWebCities(letter, country) {
		const regex = "^[" + letter.charAt(0).toUpperCase() + "" + letter.charAt(0).toLowerCase() + "]";
		const where = encodeURIComponent(JSON.stringify({
			"country": {
				"__type": "Pointer",
				"className": "Continentscountriescities_Country",
				"objectId": country
			},
			"name": {
				"$regex": regex
			}
		}));
		const response = await fetch(
			`https://parseapi.back4app.com/classes/Continentscountriescities_City?limit=9999&order=name&keys=name&where=${where}`,
			{
				headers: {
					'X-Parse-Application-Id': 'ZN0EMi73hIj4C0XZCLVB0KAwtRmBET4r3RbJTQx2',
					'X-Parse-REST-API-Key': 'DaMUksNKRDThLBF9aIJEFnlO7VTE04ZBx22oJeo1',
				}
			}
		);
		return await response.json(); // Here you have the data that you need
	}






	

}

export default new CommonService();
