import { createStore } from 'vuex';
import { language } from './language.store';
import { auth } from './auth.store';
import { testdrive } from './testdrive.store';
import { customer } from './customer.store';
import { company } from './company.store';
import { vehicle } from './vehicle.store';
import { product } from './product.store';
import store from '@/store';
import { ElNotification } from 'element-plus';

export default createStore({
	state: {
	},
	notifications: {
		showSomething: {
			title: 'Something happened',
			message: 'See console for details',
			type: 'error'
		}
	},
	mutations: {
		reset(state) {
			testdrive.state.items = [];
			testdrive.state.statuses = [];

			customer.state.items = [];

			company.state.items = [];

			vehicle.state.orders = [];
			vehicle.state.vehicles = [];
			vehicle.state.vehiclesSearch = { input: '', view: 2, companyID: 0 };
			vehicle.state.statuses = [];
			vehicle.state.warrantyClaims = [];
			vehicle.state.warrantyClaimSearch = { input: '', view: 2, companyID: 0 };

			product.state.products = [];
			product.state.stockOrders = [];
			product.state.stockInvoices = [];
			product.state.stockTakings = [];
			product.state.productOrders = [];
			product.state.productInvoices = [];

		},
		error: (state, err) => {
			//console.log('store error - ', err);
			var msg = null;
			if (err.response) {
				msg = err.result ? err.result : err.response.data ? err.response.data.message : 'Error';
				//console.log('Error Response', err);
			} else if (err.request) {
				msg = err.result ? err.result : err.message ? err.message : 'Error';
				//console.log('Error Request', err);
			} else {
				msg = err.result ? err.result : err.message ? err.message : 'Error';
			}
			ElNotification.error({
				title: err.title ? err.title : err.name === 'AxiosError' ? 'Error' : err.name,
				message: msg,
				showClose: true,
				duration: 3000,
				type: 'error',
			});
		},
	},
	actions: {
		reset({ commit }) {
			commit('reset');
		},
		error({ commit }, data) {
			commit('error', data);
		},
	},
	modules: {
		language,
		auth,
		testdrive,
		customer,
		company,
		vehicle,
		product,
	},
	getters: {
		user: state => {
			var user = state.auth.user;
			return user ? user : [];
		},
		userCompany: state => {
			var user = state.auth.user;
			if (!user) return [];
			var companyID = user.companyID;
			if (companyID > 0) {
				return user.companies.find(company => company.companyID === user.companyID);
			}
			else {
				if (user.companies && user.companies.length > 1) {
					////return user.companies ? (user.companies[0] ? user.companies[0] : {}) : {};

					return { companyID: 0, companyName: 'ALL' }
				}
				else {
					return user.companies ? (user.companies[0] ? user.companies[0] : {}) : {};
				}
			}
		},
		userCompanies: state => {
			var user = state.auth.user;
			return user ? user.companies ? user.companies : [] : [];
		},
		userPermissions: state => {
			var user = state.auth.user;
			return user ? user.permissions ? user.permissions : [] : [];
		},
		pagePermissions: state => {
			var user = state.auth.user;
			return user ? user.pagePermissions ? user.pagePermissions : [] : [];
		},
		hasPermission: (state) => (permission) => {
			var user = state.auth.user;
			var permissions = user ? user.pagePermissions ? user.pagePermissions : [] : [];
			var found = permissions.findIndex(element => element.hash === permission);
			if (found > -1) return true;
			return false;
		},
		userNotifications: state => {
			var user = state.auth.user;
			return user ? user.notifications ? user.notifications : [] : [];
		},
		userLanguage: state => {
			return state.language.language ? state.language.language : 'en';
		},
		languages: state => {
			var languages = state.language.languages;
			return languages ? languages : [];
		},
	},
	strict: process.env.NODE_ENV !== 'production'
})
