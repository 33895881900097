<template>
    <svg class="flag" v-if="flagCode==='lt'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 5 3"><path fill="#BE3A34" d="M0 0h5v3H0z" /><path fill="#046A38" d="M0 0h5v2H0z" /><path fill="#FFB81C" d="M0 0h5v1H0z" /></svg>
    <svg class="flag" v-else-if="flagCode==='lv'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 6 3"><path fill="#9D2235" d="M0 0h6v3H0" /><path fill="#FFF" d="M0 1.2h6v.6H0" /></svg>
    <svg class="flag" v-else-if="flagCode==='ee'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 33 21"><path fill="#FFF" d="M0 0h33v21H0z" /><path d="M0 0h33v14H0z" /><path fill="#0072CE" d="M0 0h33v7H0z" /></svg>
    <svg class="flag" v-else-if="flagCode==='pl'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 10"><path fill="#fff" d="M0 0h16v10H0z" /><path fill="#dc143c" d="M0 5h16v5H0z" /></svg>
    <svg class="flag" v-else-if="flagCode==='fi'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1800 1100"><rect width="1800" height="1100" fill="#fff" /><rect width="1800" height="300" y="400" fill="#003580" /><rect width="300" height="1100" x="500" fill="#003580" /></svg>
    <svg class="flag" v-else-if="flagCode==='us'" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 7410 3900"><path fill="#b22234" d="M0 0h7410v3900H0z" /><path d="M0 450h7410m0 600H0m0 600h7410m0 600H0m0 600h7410m0 600H0" stroke="#fff" stroke-width="300" /><path fill="#3c3b6e" d="M0 0h2964v2100H0z" /><g fill="#fff"><g id="d"><g id="c"><g id="e"><g id="b"><path id="a" d="M247 90l70.534 217.082-184.66-134.164h228.253L176.466 307.082z" /><use xlink:href="#a" y="420" /><use xlink:href="#a" y="840" /><use xlink:href="#a" y="1260" /></g><use xlink:href="#a" y="1680" /></g><use xlink:href="#b" x="247" y="210" /></g><use xlink:href="#c" x="494" /></g><use xlink:href="#d" x="988" /><use xlink:href="#c" x="1976" /><use xlink:href="#e" x="2470" /></g></svg>
    <svg class="flag" v-else-if="flagCode==='ru'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 9 6"><path fill="#fff" d="M0 0h9v3H0z" /><path fill="#DA291C" d="M0 3h9v3H0z" /><path fill="#0032A0" d="M0 2h9v2H0z" /></svg>
    <svg class="flag" v-else-if="flagCode==='cn'" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 30 20"><defs><path id="a" d="M0-1L.588.809-.952-.309H.952L-.588.809z" fill="#FF0" /></defs><path fill="#EE1C25" d="M0 0h30v20H0z" /><use xlink:href="#a" transform="matrix(3 0 0 3 5 5)" /><use xlink:href="#a" transform="rotate(23.036 .093 25.536)" /><use xlink:href="#a" transform="rotate(45.87 1.273 16.18)" /><use xlink:href="#a" transform="rotate(69.945 .996 12.078)" /><use xlink:href="#a" transform="rotate(20.66 -19.689 31.932)" /></svg>
    <img class="flag" v-else-if="flagCode!==''" :src="'/images/flags/' + flagCode + '.svg'" />
    <svg class="flag" v-else style="width:24px;height:24px" viewBox="0 0 24 24"><path fill="currentColor" d="M14.4,6L14,4H5V21H7V14H12.6L13,16H20V6H14.4Z" /></svg>
</template>
<script>///begin


    export default {
        props: {
            code: {
                type: String, default: ''
            },
        },
        computed: {
            flagCode: function () {
                return this.code ? this.code.toLowerCase() : '';
            },
        }
    }</script>
<style scoped>
    .flag {
        display: inline-block;
        vertical-align: baseline;
        margin-bottom: -2px;
        box-shadow: 0 .5rem 1rem rgba(0,0,0,.15) !important;
        border: solid 1px rgb(147,147,147,.5);
        object-fit: scale-down;
    }
</style>