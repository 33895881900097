import api from "./api";
import TokenService from "./token.service";
import store from "../store";

class AuthService {
	login(login) {
		return api
			.post("/users/authenticate", login)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return null;
			});
	}

	loginExt(login) {
		return api
			.post("/users/login-external", login)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return null;
			});
	}

	getExternalLogins() {
		return api
			.post("/users/get-external-logins")
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}

	logout() {
		return api.post("/users/revoketoken");
		TokenService.removeUser();
	}

	getNotifications(input) {
		return api
			.post("/users/get-notifications", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return [];
			});
	}

	checkEmail(input) {
		return api
			.post("/users/check-email", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}

	resetPassword(input) {
		return api
			.post("/users/reset-password", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}

	pingServer() {
		if (store.state.auth.user) {
			return api.get('/users/ping');
		}
		else {
			return Promise.resolve({ data: null });
		}
	}
}

export default new AuthService();
