import CompanyService from "@/services/company.service";

export const company = {
	namespaced: true,
	state: {
		items: []
	},
	actions: {
		search({ commit }, data) {
			return CompanyService.search(data).then(
				data => {
					commit('search_OK', data);
					return Promise.resolve(data);
				},
				error => {
					commit('search_ERR');
					return Promise.reject(error);
				}
			);
		},
		update({ commit }, data) {
			commit('update_OK', data);
		},
	},
	mutations: {
		search_OK(state, items) {
			state.items = items;
		},
		search_ERR(state) {
			state.items = [];
		},
		update_OK: (state, item) => {
			const existsAtIndex = state.items.findIndex(u => u.companyID === item.companyID);
			if (existsAtIndex !== -1) {
				state.items[existsAtIndex] = item;
			} else {
				state.items.unshift(item); ///instead of push
			}
			state.items = [...state.items];
		},

	}
};

