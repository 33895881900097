import api from './api';
import store from '../store';


class CompanyService {

	search(input) {
		return api
			.post("/company/search", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}

	get(input) {
		return api
			.post("/company/get", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}

	saveCompany(input) {
		return api
			.post("/company/save-company", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}

	saveAddress(input) {
		return api
			.post("/company/save-address", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}

	deleteAddress(input) {
		return api
			.post("/company/delete-address", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}

	checkEmail(input) {
		return api
			.post("/company/check-email", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}

	getPermissions(input) {
		return api
			.post("/company/get-permissions", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}

	getUserCompanies(input) {
		return api
			.post("/company/get-user-companies", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}

	saveUser(input) {
		return api
			.post("/company/save-user", input)
			.then((response) => {
				return response.data;
			});
	}

	deleteUser(input) {
		return api
			.post("/company/delete-user", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}

	saveWarehouse(input) {
		return api
			.post("/company/save-warehouse", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}

	deleteWarehouse(input) {
		return api
			.post("/company/delete-warehouse", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}

	saveLocation(input) {
		return api
			.post("/company/save-location", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}

	deleteLocation(input) {
		return api
			.post("/company/delete-location", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}

}

export default new CompanyService();
