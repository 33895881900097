import ProductService from "@/services/product.service";

export const product = {
	namespaced: true,
	state: {
		products: [],
		stockOrders: [],
		stockInvoices: [],
		stockTakings: [],
		productOrders: [],
		productInvoices: [],
	},
	actions: {
		search({ commit }, data) {
			return ProductService.search(data).then(
				data => {
					commit('search_OK', data);
					return Promise.resolve(data);
				},
				error => {
					commit('search_ERR');
					return Promise.reject(error);
				}
			);
		},
		update({ commit }, data) {
			commit('update_OK', data);
		},

		searchStockOrders({ commit }, data) {
			return ProductService.searchStockOrders(data).then(
				data => {
					commit('searchStockOrders_OK', data);
					return Promise.resolve(data);
				},
				error => {
					commit('searchStockOrders_ERR');
					return Promise.reject(error);
				}
			);
		},
		updateStockOrder({ commit }, data) {
			commit('updateStockOrder_OK', data);
		},
		deleteStockOrder({ commit }, data) {
			commit('deleteStockOrder_OK', data);
		},


		searchStockInvoices({ commit }, data) {
			return ProductService.searchStockInvoices(data).then(
				data => {
					commit('searchStockInvoices_OK', data);
					return Promise.resolve(data);
				},
				error => {
					commit('searchStockInvoices_ERR');
					return Promise.reject(error);
				}
			);
		},
		updateStockInvoice({ commit }, data) {
			commit('updateStockInvoice_OK', data);
		},
		deleteStockInvoice({ commit }, data) {
			commit('deleteStockInvoice_OK', data);
		},

		searchStockTakings({ commit }, data) {
			return ProductService.searchStockTakings(data).then(
				data => {
					commit('searchStockTakings_OK', data);
					return Promise.resolve(data);
				},
				error => {
					commit('searchStockTakings_ERR');
					return Promise.reject(error);
				}
			);
		},
		updateStockTaking({ commit }, data) {
			commit('updateStockTaking_OK', data);
		},
		deleteStockTaking({ commit }, data) {
			commit('deleteStockTaking_OK', data);
		},

		searchProductOrders({ commit }, data) {
			return ProductService.searchProductOrders(data).then(
				data => {
					commit('searchProductOrders_OK', data);
					return Promise.resolve(data);
				},
				error => {
					commit('searchProductOrders_ERR');
					return Promise.reject(error);
				}
			);
		},
		updateProductOrder({ commit }, data) {
			commit('updateProductOrder_OK', data);
		},
		deleteProductOrder({ commit }, data) {
			commit('deleteProductOrder_OK', data);
		},

		searchProductInvoices({ commit }, data) {
			return ProductService.searchProductInvoices(data).then(
				data => {
					commit('searchProductInvoices_OK', data);
					return Promise.resolve(data);
				},
				error => {
					commit('searchProductInvoices_ERR');
					return Promise.reject(error);
				}
			);
		},
		updateProductInvoice({ commit }, data) {
			commit('updateProductInvoice_OK', data);
		},
		deleteProductInvoice({ commit }, data) {
			commit('deleteProductInvoice_OK', data);
		},


	},
	mutations: {
		search_OK(state, items) {
			state.products = items;
		},
		search_ERR(state) {
			state.products = [];
		},
		update_OK: (state, item) => {
			const existsAtIndex = state.products.findIndex(u => u.productID === item.productID);
			if (existsAtIndex !== -1) {
				state.products[existsAtIndex] = item;
			} else {
				state.products.unshift(item);
			}
			state.products = [...state.products];
		},


		searchStockOrders_OK(state, items) {
			state.stockOrders = items;
		},
		searchStockOrders_ERR(state) {
			state.stockOrders = [];
		},
		updateStockOrder_OK: (state, item) => {
			const existsAtIndex = state.stockOrders.findIndex(u => u.sOrderID === item.sOrderID);
			if (existsAtIndex !== -1) {
				state.stockOrders[existsAtIndex] = item;
			} else {
				state.stockOrders.unshift(item);
			}
			state.stockOrders = [...state.stockOrders];
		},
		deleteStockOrder_OK: (state, item) => {
			state.stockOrders = state.stockOrders.filter(u => u.sOrderID !== item.sOrderID);
			state.stockOrders = [...state.stockOrders];
		},


		searchStockInvoices_OK(state, items) {
			state.stockInvoices = items;
		},
		searchStockInvoices_ERR(state) {
			state.stockInvoices = [];
		},
		updateStockInvoice_OK: (state, item) => {
			const existsAtIndex = state.stockInvoices.findIndex(u => u.sInvoiceID === item.sInvoiceID);
			if (existsAtIndex !== -1) {
				state.stockInvoices[existsAtIndex] = item;
			} else {
				state.stockInvoices.unshift(item);
			}
			state.stockInvoices = [...state.stockInvoices];
		},
		deleteStockInvoice_OK: (state, item) => {
			state.stockInvoices = state.stockInvoices.filter(u => u.sInvoiceID !== item.sInvoiceID);
			state.stockInvoices = [...state.stockInvoices];
		},


		searchStockTakings_OK(state, items) {
			state.stockTakings = items;
		},
		searchStockTakings_ERR(state) {
			state.stockTakings = [];
		},
		updateStockTaking_OK: (state, item) => {
			const existsAtIndex = state.stockTakings.findIndex(u => u.stockTakingID === item.stockTakingID);
			if (existsAtIndex !== -1) {
				state.stockTakings[existsAtIndex] = item;
			} else {
				state.stockTakings.unshift(item);
			}
			state.stockTakings = [...state.stockTakings];
		},
		deleteStockTaking_OK: (state, item) => {
			state.stockTakings = state.stockTakings.filter(u => u.stockTakingID !== item.stockTakingID);
			state.stockTakings = [...state.stockTakings];
		},


		searchProductOrders_OK(state, items) {
			state.productOrders = items;
		},
		searchProductOrders_ERR(state) {
			state.productOrders = [];
		},
		updateProductOrder_OK: (state, item) => {
			const existsAtIndex = state.productOrders.findIndex(u => u.pOrderID === item.pOrderID);
			if (existsAtIndex !== -1) {
				state.productOrders[existsAtIndex] = item;
			} else {
				state.productOrders.unshift(item);
			}
			state.productOrders = [...state.productOrders];
		},
		deleteProductOrder_OK: (state, item) => {
			state.productOrders = state.productOrders.filter(u => u.pOrderID !== item.pOrderID);
			state.productOrders = [...state.productOrders];
		},


		searchProductInvoices_OK(state, items) {
			state.productInvoices = items;
		},
		searchProductInvoices_ERR(state) {
			state.productInvoices = [];
		},
		updateProductInvoice_OK: (state, item) => {
			const existsAtIndex = state.productInvoices.findIndex(u => u.pInvoiceID === item.pInvoiceID);
			if (existsAtIndex !== -1) {
				state.productInvoices[existsAtIndex] = item;
			} else {
				state.productInvoices.unshift(item);
			}
			state.productInvoices = [...state.productInvoices];
		},
		deleteProductInvoice_OK: (state, item) => {
			state.productInvoices = state.productInvoices.filter(u => u.pInvoiceID !== item.pInvoiceID);
			state.productInvoices = [...state.productInvoices];
		},



	}
};

