<template>
    <router-view>
    </router-view>
</template>
<script>///begin
    export default {
        name: "App",
        mounted() {
            this.getPlatformLanguages();
        },
        methods: {
            getPlatformLanguages() {
                this.$store.dispatch('language/get');
            },
        },
    };</script>
<style>
    /* gwfh.mranftl.com/fonts/roboto?subsets=cyrillic,cyrillic-ext,latin,latin-ext */
    @font-face {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        src: local(''), url('./assets/fonts/roboto-v30-latin-ext_latin_cyrillic-ext_cyrillic-regular.woff2') format('woff2'), url('./assets/fonts/roboto-v30-latin-ext_latin_cyrillic-ext_cyrillic-regular.woff') format('woff');
    }

    @font-face {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 700;
        src: local(''), url('./assets/fonts/roboto-v30-latin-ext_latin_cyrillic-ext_cyrillic-500.woff2') format('woff2'), url('./assets/fonts/roboto-v30-latin-ext_latin_cyrillic-ext_cyrillic-500.woff') format('woff');
    }
    /* space-mono-regular - latin */
    @font-face {
        font-display: swap;
        font-family: 'SpaceMono';
        font-style: normal;
        font-weight: 400;
        src: local(''), url('./assets/fonts/space-mono-v13-latin-regular.woff2') format('woff2'), url('./assets/fonts/space-mono-v13-latin-regular.woff2') format('woff');
    }
    /* space-mono-700 - latin */
    @font-face {
        font-display: swap;
        font-family: 'SpaceMono';
        font-style: normal;
        font-weight: 700;
        src: local(''), url('./assets/fonts/space-mono-v13-latin-700.woff2') format('woff2'), url('./assets/fonts/space-mono-v13-latin-700.woff2') format('woff');
    }
</style>
