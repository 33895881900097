import CommonService from '@/services/common.service';

export const language = {
	namespaced: true,
	state: {
		languages: [],
		language: 'en'
	},
	actions: {
		get({ commit }) {
			return CommonService.getPlatformLanguages().then(
				data => {
					commit('get_OK', data);
					return Promise.resolve(data);
				},
				error => {
					commit('get_ERR');
					return Promise.reject(error);
				}
			);
		},
		set({ commit }, data) {
			commit('set_OK', data);
		},
	},
	mutations: {
		get_OK(state, languages) {
			if (languages.length >= 0) {
				state.languages = languages;
			}
			else {
				state.languages = [];
			}
		},
		get_ERR(state) {
			state.languages = [];
		},
		set_OK(state, data) {
			state.language = data;
		},

	}
};

