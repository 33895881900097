<template>
    <div class="loading_wrap">
        <div class="loader_logo">
            <svg x="0px" y="0px" viewBox="0 0 600 450" style="enable-background:new 0 0 600 450;" xml:space="preserve">
            <path class="st0" d="M196.3,252.3h188.5c7.6,0,13.7,6.2,13.7,13.7v67.9c0,7.6-6.2,13.7-13.7,13.7H196.3c-7.6,0-13.7-6.2-13.7-13.7V266C182.6,258.4,188.7,252.3,196.3,252.3z" />
            <path class="st0" d="M170.6,227.9h240c9.3,0,16.9,7.6,16.9,16.9v109.8c0,9.3-7.6,16.9-16.9,16.9h-240c-9.3,0-16.9-7.6-16.9-16.9V244.8C153.6,235.5,161.2,227.9,170.6,227.9z" />
            <path class="st1" d="M259.2,253.9l-56.5,94" />
            <path class="st2" d="M377.3,253.9l-56.7,94" />
            <path class="st1" d="M319.5,253.9l-57.2,94" />
            <path class="st3" d="M434.3,271h11.4c3.5,0,6.4,2.9,6.4,6.4v40.3c0,3.5-2.9,6.4-6.4,6.4h-11.4c-3.5,0-6.4-2.9-6.4-6.4v-40.3C428,273.9,430.8,271,434.3,271z" />
            <path class="st3" d="M389.3,154.4c-0.3-1.3-1.2-2.4-2.5-2.8l-18.6-6.5l13.2-27.5c0.8-1.7,0.3-3.8-1.2-5c-1.5-1.1-3.6-1-4.9,0.4l-37.9,39.5c-0.9,1-1.3,2.4-1,3.7c0.3,1.3,1.2,2.4,2.5,2.8l18.6,6.5L344.3,193c-0.8,1.7-0.3,3.8,1.2,5c1.5,1.1,3.6,1,4.9-0.4l37.9-39.5C389.2,157.1,389.6,155.7,389.3,154.4z" />
            <path class="st3" d="M227.8,149.7c-1.1-0.1-2.1,0.2-2.8,1l-9.9,11.7l-16.4-17.2c-1-1.1-2.7-1.3-3.9-0.5c-1.2,0.8-1.7,2.3-1.1,3.7l18.2,38.5c0.4,0.9,1.4,1.6,2.4,1.8c1.1,0.1,2.1-0.2,2.8-1l9.9-11.8l16.4,17.2c1,1.1,2.7,1.3,3.9,0.5c1.2-0.8,1.7-2.3,1.1-3.7l-18.2-38.5C229.8,150.5,228.9,149.8,227.8,149.7z" />
            <path class="st3" d="M322.1,120.8c-1-1.5-2.6-2.3-4.3-2.3l-25.7,0.5l4.2-39c0.3-2.5-1.3-4.8-3.6-5.4c-2.4-0.7-4.8,0.4-5.8,2.7l-29.4,64.4c-0.7,1.6-0.6,3.4,0.4,4.9c1,1.5,2.6,2.3,4.3,2.3l25.7-0.5l-4.2,39c-0.3,2.5,1.3,4.8,3.6,5.5c2.3,0.7,4.8-0.4,5.8-2.7l29.4-64.4C323.2,124.1,323,122.3,322.1,120.8z" />
            </svg>
        </div>
        <div class="loading"></div>
    </div>
</template>

<script>export default {
        name: "Loading",
    };</script>
<style scoped>
    .st0 {
        fill: #1b3558;
        stroke: #1b3558;
        stroke-width: 10;
    }

    .st1 {
        fill: #1b3558;
        stroke: #F7F5F5;
        stroke-width: 10;
        stroke-linecap: round;
    }

    .st2 {
        fill: #1b3558;
        stroke: #FFFFFF;
        stroke-width: 10;
        stroke-linecap: round;
    }

    .st3 {
        fill: #1b3558;
    }
</style>
