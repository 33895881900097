import api from './api';
import store from '../store';

class VehicleService {

	/*-- brand --*/

	getBrands(input) {
		return api
			.post("/vehicle/get-brands", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}

	addBrand(input) {
		return api
			.post("/vehicle/add-brand", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}

	getBrand(input) {
		return api
			.post("/vehicle/get-brand", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}

	saveBrand(input) {
		return api
			.post("/vehicle/save-brand", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}

	saveBrandSortOrder(input) {
		return api
			.post("/vehicle/save-brand-sort", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}

	/*-- model --*/

	getEquipmentGroups() {
		return api
			.get("/vehicle/get-equipment-groups")
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}

	getTechnicalData() {
		return api
			.get("/vehicle/get-technical-data")
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}

	getTechnicalCategories() {
		return api
			.get("/vehicle/get-technical-categories")
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}

	saveTechnicalCategory(input) {
		return api
			.post("/vehicle/save-technical-category", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}

	saveTechnicalCategoriesSortOrder(input) {
		return api
			.post("/vehicle/save-technical-category-sort", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}

	deleteTechnicalCategory(input) {
		return api
			.post("/vehicle/delete-technical-category", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}

	getWarranties() {
		return api
			.get("/vehicle/get-warranties")
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}

	addModel(input) {
		return api
			.post("/vehicle/add-model", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}

	saveModel(input) {
		return api
			.post("/vehicle/save-model", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}

	saveModelSortOrder(input) {
		return api
			.post("/vehicle/save-model-sort", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}

	getModel(input) {
		return api
			.post("/vehicle/get-model", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}

	/*-- market --*/

	saveMarket(input) {
		return api
			.post("/vehicle/save-market", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}

	/*-- body --*/

	saveBody(input) {
		return api
			.post("/vehicle/save-body", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}
	deleteBody(input) {
		return api
			.post("/vehicle/delete-body", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}
	saveBodySortOrder(input) {
		return api
			.post("/vehicle/save-body-sort", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}

	/*-- trim level --*/

	saveTrimLevel(input) {
		return api
			.post("/vehicle/save-trim-level", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}
	deleteTrimLevel(input) {
		return api
			.post("/vehicle/delete-trim-level", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}
	saveTrimLevelSortOrder(input) {
		return api
			.post("/vehicle/save-trim-level-sort", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}

	/*-- colour --*/

	saveColour(input) {
		return api
			.post("/vehicle/save-colour", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}
	deleteColour(input) {
		return api
			.post("/vehicle/delete-colour", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}
	saveColourSortOrder(input) {
		return api
			.post("/vehicle/save-colour-sort", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}

	/*-- trim --*/

	saveTrim(input) {
		return api
			.post("/vehicle/save-trim", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}
	deleteTrim(input) {
		return api
			.post("/vehicle/delete-trim", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}
	saveTrimSortOrder(input) {
		return api
			.post("/vehicle/save-trim-sort", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}

	/*-- drive train --*/

	saveDrivetrain(input) {
		return api
			.post("/vehicle/save-drivetrain", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}
	deleteDrivetrain(input) {
		return api
			.post("/vehicle/delete-drivetrain", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}
	saveDrivetrainSortOrder(input) {
		return api
			.post("/vehicle/save-drivetrain-sort", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}



	/*-- combinations --*/

	saveExteriorCombination(input) {
		return api
			.post("/vehicle/save-exterior-combination", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}
	deleteExteriorCombination(input) {
		return api
			.post("/vehicle/delete-exterior-combination", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}

	saveInteriorCombination(input) {
		return api
			.post("/vehicle/save-interior-combination", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}
	deleteInteriorCombination(input) {
		return api
			.post("/vehicle/delete-interior-combination", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}

	saveChassisCombination(input) {
		return api
			.post("/vehicle/save-chassis-combination", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}
	deleteChassisCombination(input) {
		return api
			.post("/vehicle/delete-chassis-combination", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}


	/*-- equipment --*/

	saveEquipment(input) {
		return api
			.post("/vehicle/save-equipment", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}

	deleteEquipment(input) {
		return api
			.post("/vehicle/delete-equipment", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}

	/*-- tech. data --*/

	saveTechnicalData(input) {
		return api
			.post("/vehicle/save-technical-data", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}

	deleteTechnicalData(input) {
		return api
			.post("/vehicle/delete-technical-data", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}

	saveVehicleWarranty(input) {
		return api
			.post("/vehicle/save-vehicle-warranty", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}

	deleteVehicleWarranty(input) {
		return api
			.post("/vehicle/delete-vehicle-warranty", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}

	/*-- warranties --*/

	saveWarranty(input) {
		return api
			.post("/vehicle/save-warranty", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}

	deleteWarranty(input) {
		return api
			.post("/vehicle/delete-warranty", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}

	/*-- order --*/

	getBrandsForOrder(input) {
		return api
			.post("/vehicle/get-brand-order", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return [];
			});
	}


	getModelForOrder(input) {
		return api
			.post("/vehicle/get-model-order", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}

	saveModelOrder(input) {
		return api
			.post("/vehicle/save-model-order", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}

	searchOrders(input) {
		return api
			.post("/vehicle/search-order", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}

	getOrder(input) {
		return api
			.post("/vehicle/get-order", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}

	getModelOrderVehicles(input) {
		return api
			.post("/vehicle/get-order-vehicles", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}

	updateOrder(input) {
		return api
			.post("/vehicle/update-order", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}

	removeOrderVehicle(input) {
		return api
			.post("/vehicle/remove-order-vehicle", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}

	reserveOrderVehicle(input) {
		return api
			.post("/vehicle/reserve-order-vehicle", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}

	cancelOrder(input) {
		return api
			.post("/vehicle/cancel-order", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}

	deleteOrder(input) {
		return api
			.post("/vehicle/delete-order", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}

	getPaymentOptions(input) {
		return api
			.post("/vehicle/get-payment-options", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}

	saveVehicleOrderPayment(input) {
		return api
			.post("/vehicle/save-vehicle-order-payment", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}

	deleteVehicleOrderPayment(input) {
		return api
			.post("/vehicle/delete-vehicle-order-payment", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}

	saveVehicleOrderInquiry(input) {
		return api
			.post("/vehicle/save-vehicle-order-inquiry", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}

	deleteVehicleOrderInquiry(input) {
		return api
			.post("/vehicle/delete-vehicle-order-inquiry", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}

	saveVehicleRegistration(input) {
		return api
			.post("/vehicle/save-vehicle-registration", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}

	saveVehicleExDemo(input) {
		return api
			.post("/vehicle/save-vehicle-exdemo", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}

	/*-- vehicles --*/

	getVehicleLookups(input) {
		return api
			.post("/vehicle/get-lookups", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}

	searchVehicles(input) {
		return api
			.post("/vehicle/search-vehicles", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return [];
			});
	}

	getVehicle(input) {
		return api
			.post("/vehicle/get-vehicle", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}

	saveVehicle(input) {
		return api
			.post("/vehicle/save-vehicle", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}

	saveVehicleWizard(input) {
		return api
			.post("/vehicle/save-vehicle-wizard", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}

	checkVIN(input) {
		return api
			.post("/vehicle/check-vin", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}

	checkLicNo(input) {
		return api
			.post("/vehicle/check-licno", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}

	checkUrl(input) {
		return api
			.post("/vehicle/check-url", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}

	getSuppliers(input) {
		return api
			.post("/vehicle/get-suppliers", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return [];
			});
	}

	saveVehicleToStock(input) {
		return api
			.post("/vehicle/save-vehicle-to-stock", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}

	getVehicleDealers(input) {
		return api
			.post("/vehicle/get-vehicle-dealers", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return [];
			});
	}

	getCompanyAddresses(input) {
		return api
			.post("/vehicle/get-dealer-addresses", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return [];
			});
	}

	saveVehicleTrade(input) {
		return api
			.post("/vehicle/save-vehicles-trade", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}

	acceptVehicleTrade(input) {
		return api
			.post("/vehicle/accept-vehicle-trade", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}

	declineVehicleTrade(input) {
		return api
			.post("/vehicle/decline-vehicle-trade", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}

	getBrandsForTrade(input) {
		return api
			.post("/vehicle/get-brand-trade", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return [];
			});
	}

	getModelForTrade(input) {
		return api
			.post("/vehicle/get-model-trade", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}

	saveVehicleTradeRequest(input) {
		return api
			.post("/vehicle/save-vehicles-trade-request", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return [];
			});
	}

	getVehicleTradeRequests(input) {
		return api
			.post("/vehicle/get-vehicles-trade-requests", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return [];
			});
	}

	deleteVehicleTradeRequest(input) {
		return api
			.post("/vehicle/delete-vehicles-trade-request", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return { error: error.message };
			});
	}

	getModelFreeStockQty(input) {
		return api
			.post("/vehicle/get-vehicle-free-stock", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return [];
			});
	}

	getDealerFreeStock(input) {
		return api
			.post("/vehicle/get-dealer-free-stock", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return [];
			});
	}

	getVehicleStockCostTypes() {
		return api
			.get("/vehicle/get-vehicle-stock-cost-types")
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return [];
			});
	}

	saveVehicleStockCost(input) {
		return api
			.post("/vehicle/save-vehicle-stock-cost", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				return { error: error.message };
			});
	}

	deleteVehicleStockCost(input) {
		return api
			.post("/vehicle/delete-vehicle-stock-cost", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				return { error: error.message };
			});
	}

	getVehicleInvoicePrint(input) {
		return api
			.post("/vehicle/get-vehicle-invoice-print", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}

	//warrranties / claims / service

	getCompanyVehicles(input) {
		return api
			.post("/vehicle/get-company-vehicles", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}

	saveWarrantyClaim(input) {
		return api
			.post("/vehicle/save-warranty-claim", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return [];
			});
	}

	saveWarrantyClaimAccounted(input) {
		return api
			.post("/vehicle/save-warranty-claim-accounted", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return [];
			});
	}

	searchWarrantyClaims(input) {
		return api
			.post("/vehicle/search-warranty-claims", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return [];
			});
	}

	getWarrantyClaim(input) {
		return api
			.post("/vehicle/get-warranty-claim", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}

	saveWarrantyClaimRow(input) {
		return api
			.post("/vehicle/save-warranty-claim-row", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return [];
			});
	}

	deleteWarrantyClaimRow(input) {
		return api
			.post("/vehicle/delete-warranty-claim-row", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return [];
			});
	}

	saveWarrantyClaimApproval(input) {
		return api
			.post("/vehicle/save-warranty-claim-approval", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return [];
			});
	}

	saveWarrantyClaimSubmit(input) {
		return api
			.post("/vehicle/save-warranty-claim-submit", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return [];
			});
	}

	deleteWarrantyClaim(input) {
		return api
			.post("/vehicle/delete-warranty-claim", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return [];
			});
	}

	/*-- maintenances --*/

	saveMaintenance(input) {
		return api
			.post("/vehicle/save-maintenance", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}

	saveMaintenanceSortOrder(input) {
		return api
			.post("/vehicle/save-maintenance-sort", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}

	deleteMaintenance(input) {//todo
		return api
			.post("/vehicle/delete-maintenance", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}

	saveMaintenanceRow(input) {
		return api
			.post("/vehicle/save-maintenance-row", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return [];
			});
	}

	deleteMaintenanceRow(input) {
		return api
			.post("/vehicle/delete-maintenance-row", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return [];
			});
	}

	/*-- maintenance schedules --*/

	saveMaintenanceSchedule(input) {
		return api
			.post("/vehicle/save-maintenance-schedule", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}

	saveMaintenanceScheduleSortOrder(input) {
		return api
			.post("/vehicle/save-maintenance-schedule-sort", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}

	deleteMaintenanceSchedule(input) {
		return api
			.post("/vehicle/delete-maintenance-schedule", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}

	/*-- vehicle maintenances --*/

	searchMaintenances(input) {
		return api
			.post("/vehicle/search-vehicle-maintenances", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return [];
			});
	}

	saveVehicleMaintenance(input) {
		return api
			.post("/vehicle/save-vehicle-maintenance", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}

	getVehicleMaintenance(input) {
		return api
			.post("/vehicle/get-vehicle-maintenance", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}

	deleteVehicleMaintenance(input) {
		return api
			.post("/vehicle/delete-vehicle-maintenance", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}

	saveVehicleMaintenanceRow(input) {
		return api
			.post("/vehicle/save-vehicle-maintenance-row", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return [];
			});
	}

	deleteVehicleMaintenanceRow(input) {
		return api
			.post("/vehicle/delete-vehicle-maintenance-row", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return [];
			});
	}


	/*-- vehicle service report --*/

	searchServiceReports(input) {
		return api
			.post("/vehicle/search-service-reports", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return [];
			});
	}

	saveServiceReport(input) {
		return api
			.post("/vehicle/save-service-report", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}

	getServiceReport(input) {
		return api
			.post("/vehicle/get-service-report", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}

	deleteServiceReport(input) {
		return api
			.post("/vehicle/delete-service-report", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return {};
			});
	}

	saveServiceReportSubmit(input) {
		return api
			.post("/vehicle/save-service-report-submit", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return [];
			});
	}

	saveServiceReportApproval(input) {
		return api
			.post("/vehicle/save-service-report-approval", input)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				store.dispatch('error', error);
				return [];
			});
	}

}

export default new VehicleService();
